import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useContext, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import useBraze from '../hooks/useBraze';
import useOneTrustButton from '../hooks/useOneTrustButton';
import useSideBar from '../hooks/useSideBar';
import { LocationAndLocalizationContext } from '../providers/LocationAndLocalizationProvider';
import {
  selectBottomAdBannerHeight,
  selectIsDesktop,
  selectIsDiscord,
  selectIsMobile,
  selectTopBannerHeight,
} from '../selectors/app';
import {
  selectIsSubscriptionEnabled,
  selectSubscriptionSuccessPartnerConfig,
} from '../selectors/config';
import { selectShouldShowDesktopUpdateBanner } from '../selectors/desktop';
import { selectAuthViewType } from '../selectors/loginDetails';
import { ThemeProvider } from '../styles/muiThemes';
import determineAdEligibility from '../utils/ads/determineAdEligibility';
import determineSponsorshipEnabled from '../utils/ads/determineSponsorshipEnabled';
import getTopBannerOffsetStyles from '../utils/banner/getTopBannerOffsetStyles';
import hideHeaderHelper from '../utils/hideHeaderHelper';
import shouldShowPlayer from '../utils/playerStatus/shouldShowPlayer';
import { showAppToastInPlayer } from '../utils/showAppToastInPlayer';
import Loader from './Loader';
import BottomBanner from './ads/BottomBanner';
import WithAds from './ads/WithAdsLoader';
import appCss from './app.module.scss';
import { InfoBanner } from './banner/InfoBanner';
import InstallDesktopUpdateBanner from './desktop/InstallDesktopUpdateBanner';
import Footer from './footer/Footer';
import GlobalDialogs from './globalDialogs/GlobalDialogsLoader';
import { BrowsiesHeader } from './header/BrowsiesHeader';
import Header from './header/Header';
import MessagePopover from './messagePopover/MessagePopover';
import WithPlayer from './player/WithPlayerLoader';
import ScrollReset from './shared/ScrollReset';
import LeftSideLoader from './sidebar/LeftSideLoader';
import RightSide from './sidebar/RightSide';

function getContentStyles(routeProps, topBannerHeight, bottomBannerHeight) {
  const contentStyles = {};

  if (routeProps.bgColor) {
    contentStyles.backgroundColor = routeProps.bgColor;
  }
  if (bottomBannerHeight) {
    contentStyles.paddingBottom = bottomBannerHeight;
  }

  if (topBannerHeight) {
    Object.assign(contentStyles, getTopBannerOffsetStyles(topBannerHeight));
  }

  return contentStyles;
}

export default function AppRoot({
  breakpoint,
  route,
  routeProps = {},
  apiClient,
  history,
}) {
  const guideId = routeProps.guideContext?.guideId;
  const isMobile = useSelector(selectIsMobile);
  const isDesktop = useSelector(selectIsDesktop);
  const isDiscord = useSelector(selectIsDiscord);
  const isPlayerOpen = useSelector(shouldShowPlayer);
  const isSubscriptionEnabled = useSelector(selectIsSubscriptionEnabled);
  const shouldShowDesktopUpdateBanner = useSelector(
    selectShouldShowDesktopUpdateBanner,
  );
  const authViewType = useSelector(selectAuthViewType);
  const topBannerHeight = useSelector(selectTopBannerHeight);
  const canShowAds = useSelector(determineAdEligibility(guideId));
  const sponsorshipEnabled = useSelector(determineSponsorshipEnabled(guideId));
  const bottomAdBannerHeight = useSelector(selectBottomAdBannerHeight);
  const {
    locale,
    location: { pathname, search, query },
  } = useContext(LocationAndLocalizationContext);
  const contentScrollerRef = useRef();
  const appContentRef = useRef();
  const {
    isRightSideOpen,
    isLeftSideDocked,
    isLeftSideOpen,
    hideLeftSide,
    handleLeftSideToggle,
  } = useSideBar(breakpoint, isMobile, query, pathname, routeProps);
  const { showFloatingGdprButton } = useOneTrustButton({ isLeftSideDocked });
  const showAppToast =
    !useSelector(showAppToastInPlayer) && !routeProps.isMapViewRoute;
  useBraze(routeProps);
  const subscriptionSuccessPartnerConfig = useSelector(
    selectSubscriptionSuccessPartnerConfig,
  );

  const contentStyles = getContentStyles(
    routeProps,
    topBannerHeight,
    bottomAdBannerHeight,
  );

  const shouldHideHeader = useMemo(
    () =>
      hideHeaderHelper(
        query,
        pathname,
        authViewType,
        isMobile,
        routeProps,
        subscriptionSuccessPartnerConfig,
      ),
    [
      query,
      pathname,
      authViewType,
      isMobile,
      routeProps,
      subscriptionSuccessPartnerConfig,
    ],
  );

  const showSidebarToggle = !hideLeftSide && !isLeftSideDocked;

  const sidePadding = {
    [appCss.rightSideOpen]: isRightSideOpen,
    [appCss.leftSideOpen]: !hideLeftSide ? isLeftSideOpen : false,
    [appCss.homeLeftSideOpen]: routeProps.isLanding,
  };

  const contentProps = {
    breakpoint,
    routeProps,
    history,
    canShowAds,
    isLeftSideDocked,
    isDiscord,
    sponsorshipEnabled,
  };

  const isMinimalFooter = isDiscord || isDesktop;

  return (
    <ThemeProvider>
      {!routeProps.disablePlayer && (
        <WithPlayer
          apiClient={apiClient}
          history={history}
          breakpoint={breakpoint}
          routeProps={routeProps}
          isDiscord={isDiscord}
        />
      )}
      {!routeProps.hideAds && (
        <WithAds routeProps={routeProps} locale={locale} />
      )}
      <InfoBanner />
      {shouldShowDesktopUpdateBanner && <InstallDesktopUpdateBanner />}
      {routeProps.showBrowsiesHeader && (
        <BrowsiesHeader
          guideId={guideId}
          contentScrollerRef={contentScrollerRef}
          appContentRef={appContentRef}
        />
      )}
      <ScrollReset
        id="appRootScrollReset"
        ref={contentScrollerRef}
        style={contentStyles}
        scrollHash={`${pathname}${search}`}
        className={classNames('scroller', appCss.app, {
          [appCss.isLanding]: routeProps.isLanding,
          [appCss.appWithPlayer]: isPlayerOpen,
          [appCss.noHeader]: shouldHideHeader,
        })}
      >
        {!shouldHideHeader && (
          <Header
            showSidebarToggle={showSidebarToggle}
            onSidebarToggle={handleLeftSideToggle}
            isLanding={routeProps.isLanding}
            isMinimalHeader={routeProps.isMinimalHeader}
            unlinkedLogo={routeProps.unlinkedLogo}
            sourceGuideId={guideId}
            isDesktop={isDesktop}
            isLeftSideOpen={isLeftSideOpen}
          />
        )}
        {isRightSideOpen && (
          <RightSide
            breakpoint={breakpoint}
            hideRightSidebarUpsell={routeProps.hideRightSidebarUpsell}
            matchUrl={routeProps.matchUrl}
            guideId={guideId}
            canShowAds={canShowAds}
            sponsorshipEnabled={sponsorshipEnabled}
          />
        )}
        {!hideLeftSide && (
          <LeftSideLoader
            isLeftSideOpen={isLeftSideOpen}
            isLeftSideDocked={isLeftSideDocked}
            handleLeftSideToggle={handleLeftSideToggle}
            subscriptionEnabled={isSubscriptionEnabled}
            breakpoint={breakpoint}
            currentGuideId={guideId}
            history={history}
          />
        )}
        {!routeProps.hideLoader && <Loader />}
        <div
          ref={appContentRef}
          className={classNames(appCss.content, {
            ...sidePadding,
            [appCss.isMinimalHeader]: routeProps.isMinimalHeader,
            [appCss.mobile]: isMobile,
            [appCss.isLanding]: routeProps.isLanding,
            [appCss.noHeader]: shouldHideHeader,
          })}
        >
          {renderRoutes(route.routes, contentProps)}
        </div>
        {!routeProps.hideFooter && (
          <div className={classNames(sidePadding)}>
            <Footer
              isRightSideOpen={isRightSideOpen}
              isLanding={routeProps.isLanding}
              hideLogo={isMinimalFooter}
              hideProductLinks={isMinimalFooter}
              hideSitemap={isMinimalFooter}
              hideSocial={isMinimalFooter}
              hideConsent={isDiscord}
              darkMode={isDiscord}
            />
          </div>
        )}
        <GlobalDialogs
          breakpoint={breakpoint}
          routeProps={routeProps}
          history={history}
          showFloatingGdprButton={showFloatingGdprButton}
          canShowAds={canShowAds}
          sponsorshipEnabled={sponsorshipEnabled}
        />
        {!routeProps.hideAds && (
          <BottomBanner
            breakpoint={breakpoint}
            canShowAds={canShowAds}
            routeProps={routeProps}
          />
        )}
      </ScrollReset>
      {showAppToast && (
        <MessagePopover
          className={classNames({
            [appCss.leftSideOffset]: isLeftSideOpen,
            [appCss.rightSideOffset]: isRightSideOpen,
          })}
        />
      )}
    </ThemeProvider>
  );
}

AppRoot.propTypes = {
  breakpoint: PropTypes.number.isRequired,
  route: PropTypes.object.isRequired,
  routeProps: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  apiClient: PropTypes.object,
};
