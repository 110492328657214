import { PAYMENT_COMPLETE_PATH } from 'src/common/constants/paths';
import { getSourceParam } from 'src/common/utils/queryParams';

/**
 * @param query object
 * @param pathname string i.e. /paymentcomplete/
 * @returns boolean
 */
export default function hasCustomPaymentCompletePage(
  query,
  pathname,
  subscriptionSuccessPartnerConfig = {},
) {
  return !!(
    pathname === PAYMENT_COMPLETE_PATH &&
    subscriptionSuccessPartnerConfig[getSourceParam(query)]
  );
}
