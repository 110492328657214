export default [
  {
    name: 'Instagram',
    cssClass: 'socialLinkInstagram',
    url: 'https://instagram.com/tunein',
  },
  {
    name: 'Twitter',
    cssClass: 'socialLinkTwitter',
    url: 'https://twitter.com/tunein',
  },
  {
    name: 'LinkedIn',
    cssClass: 'socialLinkLinkedIn',
    url: 'https://www.linkedin.com/company/tunein/',
  },
];
