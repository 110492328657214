import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  BOTTOM_BANNER_ENABLED,
  FREESTAR_ENABLED,
} from 'src/common/constants/experiments/ads';
import useActions from 'src/common/hooks/useActions';
import useExperiment from 'src/common/hooks/useExperiment';
import { selectBottomAdBannerHeight } from 'src/common/selectors/app';
import {
  getDisplayAdAttributes,
  gptSlotNames,
} from 'src/common/utils/ads/config';
import shouldShowPlayer from 'src/common/utils/playerStatus/shouldShowPlayer';
import isContentPage from 'src/common/utils/subscription/isContentPage';
import { setBottomAdBannerHeight } from '../../actions/app';
import DisplayAd from './DisplayAd';
import styles from './bottom-banner.module.scss';

export default function BottomBanner(props) {
  const { canShowAds, routeProps } = props;
  const isAdEligiblePage = isContentPage(routeProps);

  const bannerRef = useRef();
  const actions = useActions({ setBottomAdBannerHeight });

  const isPlayerOpen = useSelector(shouldShowPlayer);
  const bottomAdBannerHeight = useSelector(selectBottomAdBannerHeight);
  const isFreestarEnabled = useExperiment(FREESTAR_ENABLED);
  const isBottomBannerEnabled = useExperiment(BOTTOM_BANNER_ENABLED);

  const showDisplayAd = canShowAds && isBottomBannerEnabled && isAdEligiblePage;

  useEffect(() => {
    if (showDisplayAd) {
      const resizeObserver = new ResizeObserver(() => {
        if (bannerRef.current) {
          const height = bannerRef.current.offsetHeight || 0;
          actions.setBottomAdBannerHeight(height);
        }
      });

      if (bannerRef.current) {
        resizeObserver.observe(bannerRef.current);
      }

      return () => {
        actions.setBottomAdBannerHeight(0);
        resizeObserver.disconnect();
      };
    }
  }, [actions, showDisplayAd]);

  return !showDisplayAd ? null : (
    <div
      ref={bannerRef}
      className={classNames(styles.container, {
        [styles.withPlayer]: isPlayerOpen,
        // This is to prevent rendering bottom border when no content rendered yet
        [styles.withBorder]: isPlayerOpen && bottomAdBannerHeight,
      })}
    >
      <DisplayAd
        guideId={routeProps.guideContext?.guideId}
        matchUrl={routeProps.matchUrl}
        {...getDisplayAdAttributes(
          gptSlotNames.bottom_banner,
          isFreestarEnabled,
        )}
        {...props}
      />
    </div>
  );
}

BottomBanner.property = {
  canShowAds: PropTypes.bool,
  breakpoint: PropTypes.number,
  routeProps: PropTypes.object,
};
